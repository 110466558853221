.jtbdContainer {
  gap: var(--dig-spacing__dimension__16);
  justify-content: center;
}

.jtbdOption {
  cursor: pointer;
}

.jtbdOption > div {
  display: flex;
  flex-direction: column;
  background: var(--dig-color__background__raised);
  height: 424px;
  padding: var(--dig-spacing__dimension__16) var(--dig-spacing__dimension__32);
}

.jtbdOption:focus {
  box-shadow: none;
}

.jtbdOption.selected {
  box-shadow: 0 0 0 3px var(--dig-color__primary-cta__base);
}

.jtbdImgContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.jtbdImgContainer img {
  max-width: 100%;
}

.jtbdDescription {
  color: var(--dig-color__text__subtle);
}

@media (max-width: 599px) {
  .jtbdContainer {
    flex-direction: column;
  }
  .jtbdOption {
    width: 100%;
  }
}

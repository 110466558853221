.actionButton {
  border-radius: var(
    --dig-radius__xlarge
  ) !important /* important is required in order to override dig overrides */;
}

.actionButton :global(.dig-Button-content) {
  padding-left: var(--spacing__unit--3);
  padding-right: var(--spacing__unit--3);
}

.actionButtonSpacers {
  flex: 1;
}

.primaryActions {
  gap: var(--dig-spacing__micro__xsmall);
}

.privacyLink {
  color: var(--dig-color__text__subtle);
  font-size: var(--dig-type__size__text__large);
  text-decoration: underline;
}

.legalNote {
  padding: var(--spacing__unit--3);
}

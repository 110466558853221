.scopedButtonsBoxRow {
  display: flex;
  gap: var(--dig-spacing__micro__medium);
  padding-left: var(--dig-spacing__micro__large);
  padding-bottom: var(--dig-spacing__micro__large);
}

.disableButtonRow {
  pointer-events: none;
}

.container {
  min-width: 240px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--dig-color__background__base);
}

.content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.modelSelector {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border: 1px solid var(--dig-color__border__default);
  border-radius: 4px;
  background: var(--dig-color__background__default);
  cursor: pointer;
  margin-right: 8px;
}

.modelSelector:hover {
  background: var(--dig-color__background__hover);
}

.editorContainer {
  background: red;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px;
  background-color: var(--background-secondary);
  border-radius: 8px;
  flex: 1;
  min-height: 200px;
  overflow: auto;
}

.editorContent {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.editorSuggestions {
  margin-top: 16px;
  border-top: 1px solid var(--border-default);
  padding-top: 16px;
}

.editorModifications {
  margin-top: 16px;
  border-top: 1px solid var(--border-default);
  padding-top: 16px;
}

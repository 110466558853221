.root {
  flex: 1 0 auto;
  padding: var(--spacing__unit--1) 0;
  margin-bottom: var(--spacing__unit--2);
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing__unit--2) 0;
}

.multimediaHeader {
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: center;
  padding-bottom: var(--spacing__unit--2);
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  max-height: 274px;
  overflow-y: hidden;
  overflow-x: hidden;
  &::after {
    content: '';
    flex-grow: 999;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

.expanded {
  max-height: unset;
  /*  overflow-y: auto; */
}

.filters {
  display: flex;
  justify-content: flex-end;
}

.resultIcon {
  padding-top: 3px;
}

.item {
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  cursor: pointer;
  height: 285px;
}

.lastItem {
  flex: 1 1 auto;
}

.item .title {
  margin-top: var(--dig-spacing__micro__small);
  width: 100%;
  overflow: hidden;
}

.titleRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--dig-spacing__micro__xsmall);
}

.titleText {
  flex: 1 1 auto;
  overflow: hidden;
}

.icon {
  flex: 0 0 24px;
  box-shadow: none;
}

.item .previewImg {
  vertical-align: middle;
  object-fit: cover;
  width: 100%;
  height: 220px;
  /* max-width: 340px; */
  min-width: 160px;
  background: #f9f9f9;
}

.videoOverlay {
  position: absolute;
  right: 48%;
  top: 100px;
}

/* .item .previewImg:hover {
  box-shadow: var(--dig-utilities__boxshadow__raised);
} */

.lastItem .previewImg {
  max-width: unset;
}

.headerTitle {
  padding-top: 16px;
}

.drawerTitleText {
  display: inline-block;
  margin-top: var(--dig-spacing__micro__small);
  margin-bottom: var(--dig-spacing__micro__xsmall);
}

.drawer {
  max-width: 400px;
}

.drawerHeader {
  padding: var(--dig-spacing__micro__large);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawerIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--dig-spacing__micro__large);
  gap: 16px;
  border-radius: var(--dig-radius__medium, 8px);
  border: 1px solid var(--dig-border__subtle, rgba(0, 0, 0, 0.14));
  flex: 40px auto;
}

.drawerBody {
  padding: var(--dig-spacing__macro__small);
  overflow: auto;
  width: 350px;
}

.highlight {
  background-color: var(--dig-color__highlight__yellow, #f7cc62);
}

.highlightLight {
  background-color: rgb(251, 219, 111);
}

.highlightDark {
  background-color: rgb(122, 103, 44);
}

.drawerActions {
  display: flex;
  justify-content: space-between;
}

.imageSkeleton {
  height: 220px;
  width: 340px;
}

.imageSkeletonLarge {
  width: 100% !important;
  height: 340px !important;
}

.imageSkeletonText {
  margin: 0;
}

.imageSkeletonContainer {
  width: 340px;
}

.multimediaImageLoaded {
  opacity: 1;
}

.multimediaImageLoading {
  opacity: 0;
  position: absolute;
}

.detailsHeader {
  padding-bottom: var(--dig-spacing__micro__xlarge);
}

.multimediaImageError {
  height: 220px;
  width: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.multimediaPreview {
  display: flex;
  justify-content: center;
  align-items: end;
  height: 200px;
  overflow: hidden;
  width: 100%;
  padding-top: 23px;
  background-color: var(--dig-color__background__subtle);
}

.bigPreview {
  max-width: 276px;
  max-height: 177px;
}

.dividerLine {
  background-color: var(--dig-color__border__subtle);
  height: 1px;
  width: 100%;
  margin: var(--dig-spacing__micro__xlarge) 0;
}

.metadataEntry {
  height: 32px;
}

.imageBorder {
  border: 1px solid var(--dig-color__border__subtle);
}

.multimediaImageError {
  width: calc(100% - 2px);
  min-width: 200px;
  height: 220px;
  border: 1px solid var(--dig-color__border__subtle);
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dig-color__background__subtle);
}

.truncatedLabel {
  max-width: 250px;
}

.navContainer.augustRevision {
  --dash-typeahead__border-radius: 12px;
  --dash-typeahead__max-width: 1100px;
  --omnibox-topnav-height: 100px;
}

.container {
  position: sticky;
  top: 0;
  z-index: 99;
}

.navContainer {
  display: grid;
  grid-template-columns: 1fr minmax(120px, var(--dash-typeahead__max-width)) 1fr;
  box-sizing: border-box;
  padding: var(--dig-spacing__micro__large) var(--dig-spacing__macro__small);
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--dig-color__background__base);
  border-color: rgb(0, 0, 0, 0);
}

.navContainer.aiOmnibox {
  height: var(--omnibox-topnav-height);
  border-bottom: none;
  background-color: transparent;
}

.navContainer.mobile {
  /* Mobile top nav has two rows. */
  height: 128px;
  grid-template-rows: 1fr 1fr;
  padding: var(--dig-spacing__micro__large);
  column-gap: var(--dig-spacing__micro__large);
}

.mobile .centerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileSearchBar {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 4;
  justify-content: center;
}

.leftContainer {
  grid-column: 1;
  display: flex;
  align-items: center;
  gap: var(--dig-spacing__micro__large);
  pointer-events: auto;
}

.rightContainer {
  pointer-events: auto;
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--dig-spacing__micro__medium);
}

.navContainer.showBackground {
  background-color: var(--dig-color__primary__surface);
}

.noShrink {
  flex-shrink: 0;
}

.scrollingBorder {
  border-bottom: 0.5px solid var(--dig-color__border__subtle);
  transition: border-color 0.5s;
}

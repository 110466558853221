.omnibarContainer {
  position: relative;
  border-radius: var(--omnibar-container-border-radius);
  border: 1px solid var(--dig-color__border__subtle, rgba(0, 0, 0, 0.14));
  background: var(--dig-color__background__base);
  box-shadow: 0px 10px 22px 0px rgba(0, 0, 0, 0.06);
}

.omnibarContainer.isSlimMode {
  border-radius: var(--omnibar-slim-mode-border-radius);
  padding: var(--dig-spacing__micro__medium) 0;
}

.omniboxInputContainer.extendBottom {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
